/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.Loader {
    height: 100vh;
}

.Loader__background {
    display: none !important;
    z-index: 2000 !important;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

    .Loader__background.hide {
        display: none !important;
    }

    .Loader__background.show {
        display: block !important;
    }


.trapezoid {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    z-index: 10
}

    .trapezoid.active {
        height: 30px;
        box-shadow: 0 5px 5px -5px #333;
    }

.trapezoid-purple {
    border-bottom: 32px solid #a665af;
}

.trapezoid-blue {
    border-bottom: 32px solid #3d5a80;
}

.trapezoid > span {
    display: inline-block;
    color: white;
    font-size: 14px;
    /* font-weight: bold; */
    padding: 8px 0px 8px 8px;
}

.trapezoid-head {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    text-align: center;
}

    .trapezoid-head > span {
        display: inline-block;
        color: white;
        font-size: 14px;
        padding: 8px 8px 8px 8px;
    }

.mat-box {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.master-data-header {
    background: #fff;
    height: 57px;
    overflow: hidden;
    padding: 7px 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.master-data-content {
    /*padding: 15px 20px;
    background: #f7f7f7;*/
}
