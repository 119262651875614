body {
}

ul.MuiTreeView-root {
    padding: 0px 20px;
}

ul.MuiCollapse-container.MuiTreeItem-group {
    padding-left: 10px;
}

.nav-border-to p{
    border-top: 0px;
}